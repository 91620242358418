import { Link, NavLink, useLocation } from "@remix-run/react"
import { useState, useEffect } from "react";
import { classNames } from "~/lib/util";
import { useRdt } from "~/lib/rdt/hooks/useRdt";

declare global {
   namespace JSX {
      interface IntrinsicElements {
         'radix-connect-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
   }
}

export default function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
   const location = useLocation();
   const rdt = useRdt();

   // set button layout
   useEffect(() => {
      rdt?.buttonApi.setTheme('white');
      rdt?.buttonApi.setMode('light');
   }, []);

	function toggleMenu() {
      setMenuOpen(!menuOpen);
   }

   function closeMenu() {
      if (!menuOpen) {
         return;
      }

      setMenuOpen(false);
	}
	
	return (
      <header className="sticky top-0 z-50 backdrop-blur-sm">
         <nav className="px-4 pt-6 pb-3 xl:container xl:mx-auto xl:px-0 flex flex-wrap items-center justify-start">
            <a href="/">
               <img src="https://hug.meme/hug.png" className="w-8" />
            </a>

            <ul className="ml-10 flex flex-row justify-start text-orange-800 text-lg">
               <li>
                  <NavLink to="/" className={({ isActive }) => (isActive ? 'border-orange-800' : 'border-transparent') + ' whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-orange-800'}>
                     Receive HUGs
                  </NavLink>
               </li>
               <li>
                  <NavLink to="/staking" className={({ isActive }) => (isActive ? 'border-orange-800' : 'border-transparent') + ' whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-orange-800'}>
                     Stake HUGs
                  </NavLink>
               </li>
               <li>
                  <NavLink to="/leaderboard" className={({ isActive }) => (isActive ? 'border-orange-800' : 'border-transparent') + ' whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-orange-800'}>
                     Leaderboard
                  </NavLink>
               </li>
            </ul>

            <div className="ml-auto flex items-center justify-end">
               <radix-connect-button />
            </div>
         </nav>
      </header>
   );
}